import jwtDecode from 'jwt-decode';

import api from '../api';

class AuthApi {
  async login({ user, pass }) {
    try {
      const response = await api.post('/login', {
        user,
        pass,
      });

      return response.data.token;
    } catch (error) {
      console.error(error);
      console.log(error.response.data.msg);

      return error.response.data.msg;
    }
  }

  isValidToken(accessToken) {
    if (!accessToken) {
      return false;
    }

    const decodedToken = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;

    if (!decodedToken.exp > currentTime) {
      new Error('Usuário não autorizado.');
    }
    return decodedToken.exp > currentTime;
  }
}

export const authApi = new AuthApi();
