import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useSettings } from './contexts/settings-context';
import { useAuth } from './hooks/use-auth';
import routes from './routes';
import { createCustomTheme } from './theme';

export const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isInitialized } = useAuth();

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isInitialized && content}
    </ThemeProvider>
  );
};
