import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3333'
      : 'https://api-spclog.redemarajo.tech',
});

api.interceptors.request.use(function (config) {
  const accessToken = window.localStorage.getItem('@SPCLog:accessToken');
  config.headers.Authorization = accessToken;

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!!error.response) {
      if (error.response.status === 401) {
        if (error.response.data.msg === 'Usuário não autorizado.') {
          localStorage.removeItem('@SPCLog:accessToken');
          window.location.href = '/';
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;
