import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@material-ui/core';

const tabs = [
  {
    href: '/dashboard/reports',
    label: 'Visão geral',
  },
  // {
  //   href: '/dashboard/reports/detail',
  //   label: 'Por período',
  // },
];

export const Reports = () => {
  const location = useLocation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1,
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Relatórios
            </Typography>
          </Box>
          <Tabs
            allowScrollButtonsMobile
            sx={{ mt: 2 }}
            value={tabs.findIndex((tab) => tab.href === location.pathname)}
            variant="scrollable"
          >
            {tabs.map((option) => (
              <Tab component={RouterLink} key={option.href} label={option.label} to={option.href} />
            ))}
          </Tabs>
          <Divider />
        </Box>
        <Outlet />
      </Container>
    </Box>
  );
};
