import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { LoadingScreen } from './components/loading-screen';
import { DashboardLayout } from './containers/dashboard-layout';
import { Reports } from './containers/reports';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Not found pages
const NotFound = Loadable(
  lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound })))
);

// Auth pages
const Login = Loadable(
  lazy(() => import('./containers/login').then((module) => ({ default: module.Login })))
);

// Dashboard pages
const ReportsOverview = Loadable(
  lazy(() =>
    import('./containers/reports-overview').then((module) => ({ default: module.ReportsOverview }))
  )
);

const routes = [
  // {
  //   path: '/',
  //   element: (
  //     <MainLayout>
  //       <Home />
  //     </MainLayout>
  //   )
  // },
  {
    path: '/',
    element: <Navigate to="/login" replace />,
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard/reports" replace />,
      },
      {
        path: 'reports',
        element: <Reports />,
        children: [
          {
            path: '/',
            element: <ReportsOverview />,
          },
        ],
      },
    ],
  },
  {
    path: 'docs',
    children: [
      {
        path: '/',
        element: <Navigate to="/docs/overview/welcome" replace />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
